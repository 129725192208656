var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.editMode
          ? _vm.$t("room.update-section")
          : _vm.$t("room.add-section"),
        visible: _vm.visible
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        closed: function($event) {
          return _vm.$emit("cancelDialog")
        }
      }
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.section } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("room.section-name"),
                "label-width": "120px",
                prop: "name",
                rules: { required: true }
              }
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                nativeOn: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.saveSection($event)
                  }
                },
                model: {
                  value: _vm.section.name,
                  callback: function($$v) {
                    _vm.$set(_vm.section, "name", $$v)
                  },
                  expression: "section.name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("cancelDialog")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("room.cancel")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveSection } },
            [_vm._v(_vm._s(_vm.$t("room.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }