<template>
  <el-dialog :title="editMode ? $t('room.update-room') : $t('room.add-room')" :visible.sync="visible" @closed="$emit('cancelDialog')">

    <el-form ref="form" :model="room">
      <el-form-item :label="$t('room.icon')" label-width="120px">

        <el-col :span="12">
          <el-select v-model="room.icon" filterable :placeholder="$t('room.select-icon')">
            <el-option v-for="(icon, index) in icons" :key="index" :label="icon.title" :value="icon.value">
              <span class="icon-select-layout"><img @error="onImageError" :src="'/assets/icons/' + icon.value + '.png'" class="icon-select">{{icon.title}}</span>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
              <span>
                <img @error="onImageError" :src="'/assets/icons/' + room.icon + '.png'" class="icon-contact">
              </span>
        </el-col>

      </el-form-item>

      <el-form-item :label="$t('room.room-name')" label-width="120px" prop="name" :rules="{required: true}">
        <el-input v-model="room.name" autocomplete="off" @keyup.enter.native="saveRoom"></el-input>
      </el-form-item>

      <el-form-item v-if="$store.getters.sections.length > 0" :label="$t('room.section')" label-width="120px">

        <el-select v-model="room.sectionId" :placeholder="$t('room.select-section')">
            <el-option v-for="(section, index) in $store.getters.sections" :key="index" :label="section.name" :value="section.id">
            </el-option>
          </el-select>

      </el-form-item>

    </el-form>

    <span slot="footer" class="dialog-footer">
    <el-button @click="$emit('cancelDialog')">{{ $t('room.cancel') }}</el-button>
    <el-button type="primary" @click="saveRoom">{{ $t('room.confirm') }}</el-button>
  </span>
  </el-dialog>
</template>

<script>
  export default {
    name: "NewRoomDialog",

    props: {
      visible: {
        type: Boolean,
        required: true,
        default: false
      },

      editMode: {
        type: Boolean,
        default: false
      },

      room: {}
    },

    data: function(){
      return {
        icons: [
          { title: this.$t('room.icons[0]'), value: "ic_dnd" },
          { title: this.$t('room.icons[1]'), value: "ic_gameroom" },
          { title: this.$t('room.icons[2]'), value: "ic_garden" },
          { title: this.$t('room.icons[3]'), value: "ic_balcony1" },
          { title: this.$t('room.icons[4]'), value: "ic_bar1" },
          { title: this.$t('room.icons[5]'), value: "ic_bathroom" },
          { title: this.$t('room.icons[6]'), value: "ic_bathroom1" },
          { title: this.$t('room.icons[7]'), value: "ic_bathroom2" },
          { title: this.$t('room.icons[8]'), value: "ic_bathroom3" },
          { title: this.$t('room.icons[9]'), value: "ic_bedroom1" },
          { title: this.$t('room.icons[10]'), value: "ic_bedroom2" },
          { title: this.$t('room.icons[11]'), value: "ic_bedroom3" },
          { title: this.$t('room.icons[12]'), value: "ic_bowling" },
          { title: this.$t('room.icons[13]'), value: "ic_canteen1" },
          { title: this.$t('room.icons[14]'), value: "ic_coffee1" },
          { title: this.$t('room.icons[15]'), value: "ic_conference_room1" },
          { title: this.$t('room.icons[16]'), value: "ic_dining_room1" },
          { title: this.$t('room.icons[17]'), value: "ic_dressing_room1" },
          { title: this.$t('room.icons[18]'), value: "ic_emergency_exit1" },
          { title: this.$t('room.icons[19]'), value: "ic_entrance1" },
          { title: this.$t('room.icons[20]'), value: "ic_entrance2" },
          { title: this.$t('room.icons[21]'), value: "ic_fireplace" },
          { title: this.$t('room.icons[22]'), value: "ic_garage1" },
          { title: this.$t('room.icons[23]'), value: "ic_garden1" },
          { title: this.$t('room.icons[24]'), value: "ic_gym1" },
          { title: this.$t('room.icons[25]'), value: "ic_home1" },
          { title: this.$t('room.icons[26]'), value: "ic_home2" },
          { title: this.$t('room.icons[27]'), value: "ic_kids_room1" },
          { title: this.$t('room.icons[28]'), value: "ic_kids_room2" },
          { title: this.$t('room.icons[29]'), value: "ic_kitchen1" },
          { title: this.$t('room.icons[30]'), value: "ic_kitchen2" },
          { title: this.$t('room.icons[31]'), value: "ic_laundry_room" },
          { title: this.$t('room.icons[32]'), value: "ic_laundry_room2" },
          { title: this.$t('room.icons[33]'), value: "ic_library1" },
          { title: this.$t('room.icons[34]'), value: "ic_library2" },
          { title: this.$t('room.icons[35]'), value: "ic_lift1" },
          { title: this.$t('room.icons[36]'), value: "ic_living_room1" },
          { title: this.$t('room.icons[37]'), value: "ic_living_room2" },
          { title: this.$t('room.icons[38]'), value: "ic_living_room3" },
          { title: this.$t('room.icons[39]'), value: "ic_office_building1" },
          { title: this.$t('room.icons[40]'), value: "ic_office1" },
          { title: this.$t('room.icons[41]'), value: "ic_reception1" },
          { title: this.$t('room.icons[42]'), value: "ic_sauna1" },
          { title: this.$t('room.icons[43]'), value: "ic_swimming_pool1" },
          { title: this.$t('room.icons[44]'), value: "ic_swimming_pool2" },
          { title: this.$t('room.icons[45]'), value: "ic_terrace1" },
          { title: this.$t('room.icons[46]'), value: "ic_tv_room1" },
          { title: this.$t('room.icons[47]'), value: "ic_ironing_room" },
          { title: this.$t('room.icons[48]'), value: "ic_makeup_room" },
          { title: this.$t('room.icons[49]'), value: "ic_music_room" },
          { title: this.$t('room.icons[50]'), value: "ic_painting_room" },
          { title: this.$t('room.icons[51]'), value: "ic_photo_studio" },
          { title: this.$t('room.icons[52]'), value: "ic_sport_room" },
          { title: this.$t('room.icons[53]'), value: "ic_wine_cellar1" },
          { title: this.$t('room.icons[54]'), value: "ic_wine_cellar2" },
        ]
      }
    },

    methods: {
      onImageError: function(e){
        e.target.src = "/assets/icons/ic_home1.png";
      },

      saveRoom(){
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$emit('saveRoom', this.room, this.editMode)
          }
        })
      }
    },
  }
</script>

<style scoped>
  .icon-select-layout{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon-select {
    filter: brightness(0.45);
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .icon-contact {
    filter: brightness(0.45);
    width: 40px;
    height: 40px;
  }
</style>