var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "room" },
    [
      _c(
        "el-row",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [_c("app-navigation", { attrs: { title: _vm.$t("room.rooms") } })],
            1
          ),
          _c(
            "el-col",
            { staticClass: "btn-group", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "add-button",
                  attrs: { type: "primary" },
                  on: { click: _vm.onClickAddRoom }
                },
                [_vm._v(_vm._s(_vm.$t("room.add-room")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "plain" },
                  on: { click: _vm.onClickAddSection }
                },
                [_vm._v(_vm._s(_vm.$t("room.add-section")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.sections.length > 0
        ? _c(
            "el-row",
            {
              staticClass: "header-row",
              staticStyle: { "margin-bottom": "10px", "font-weight": "bold" }
            },
            [
              _c("el-col", { attrs: { span: 2 } }, [
                _c("span", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v("#")
                ])
              ]),
              _c("el-col", { attrs: { span: 19 } }, [
                _vm._v(" " + _vm._s(_vm.$t("room.section")) + " ")
              ]),
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v("\n      " + _vm._s(_vm.$t("widget.actions")) + "\n    ")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.sections.length > 0
        ? _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames"
              }
            },
            [
              true
                ? _c(
                    "Container",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      attrs: { "drag-handle-selector": ".column-drag-handle" },
                      on: { drop: _vm.onDropSection }
                    },
                    _vm._l(_vm.sections, function(section) {
                      return _c(
                        "Draggable",
                        { key: section.id },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "draggable-section",
                              attrs: { name: section.id }
                            },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("el-col", { attrs: { span: 2 } }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "column-drag-handle",
                                        staticStyle: { padding: "20px" }
                                      },
                                      [_vm._v("☰")]
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 19 } }, [
                                    _vm._v(" " + _vm._s(section.name) + " ")
                                  ]),
                                  _c("el-col", { attrs: { span: 3 } }, [
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.onClickEditSection(
                                              section
                                            )
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "far fa-edit" })]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.removeSection(section.id)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-trash-alt"
                                        })
                                      ]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { staticClass: "room-title" },
                                [
                                  _c("el-col", { attrs: { span: 2 } }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "20px" }
                                      },
                                      [_vm._v("#")]
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 2 } }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("room.icon")) +
                                        "\n            "
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 10 } }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("room.name")) +
                                        "\n            "
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 7 } }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("room.accessory-count")) +
                                        "\n            "
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 3 } }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("widget.actions")) +
                                        "\n            "
                                    )
                                  ])
                                ],
                                1
                              ),
                              true
                                ? _c(
                                    "Container",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.loading,
                                          expression: "loading"
                                        }
                                      ],
                                      attrs: {
                                        "group-name": "section",
                                        "drag-handle-selector":
                                          ".column-drag-handle",
                                        "get-child-payload": _vm.getChildPayload(
                                          section.id
                                        )
                                      },
                                      on: {
                                        drop: function($event) {
                                          return _vm.onDrop(section, $event)
                                        }
                                      }
                                    },
                                    _vm._l(
                                      _vm.getSectionRooms(section.id),
                                      function(item) {
                                        return _c(
                                          "Draggable",
                                          { key: item.id },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "draggable-item" },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 2 } },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "column-drag-handle",
                                                        staticStyle: {
                                                          padding: "20px"
                                                        }
                                                      },
                                                      [_vm._v("☰")]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                      "margin-top": "10px",
                                                      "margin-bottom": "10px"
                                                    },
                                                    attrs: { span: 2 }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "icon-room",
                                                      attrs: {
                                                        src:
                                                          "/assets/icons/" +
                                                          item.icon +
                                                          ".png"
                                                      },
                                                      on: {
                                                        error: _vm.onImageError
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 10 } },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(item.name) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 7 } },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.accessoryCount(
                                                            item.id
                                                          )
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 3 } },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.onClickEditRoom(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "far fa-edit"
                                                        })
                                                      ]
                                                    ),
                                                    item.removable
                                                      ? _c(
                                                          "span",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.dublicateRoom(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-copy"
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.removable
                                                      ? _c(
                                                          "span",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.removeRoom(
                                                                  item.id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-trash-alt"
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "span",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$router.push(
                                                              {
                                                                name:
                                                                  "AccessoryAdd",
                                                                params: {
                                                                  roomId:
                                                                    item.id
                                                                }
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-plus"
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.sections.length <= 0
        ? _c(
            "el-row",
            {
              class: {
                "room-title": _vm.sections.length > 0,
                "room-title-nosection": _vm.sections.length <= 0
              }
            },
            [
              _c("el-col", { attrs: { span: 2 } }, [
                _c("span", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v("#")
                ])
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _vm._v("\n      " + _vm._s(_vm.$t("room.icon")) + "\n    ")
              ]),
              _c("el-col", { attrs: { span: 10 } }, [
                _vm._v("\n      " + _vm._s(_vm.$t("room.name")) + "\n    ")
              ]),
              _c("el-col", { attrs: { span: 7 } }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("room.accessory-count")) + "\n    "
                )
              ]),
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v("\n      " + _vm._s(_vm.$t("widget.actions")) + "\n    ")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.sections.length <= 0
        ? _c(
            "Container",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: { "drag-handle-selector": ".column-drag-handle" },
              on: { drop: _vm.onDropRoom }
            },
            _vm._l(_vm.items, function(item) {
              return _c("Draggable", { key: item.id }, [
                _c(
                  "div",
                  {
                    class: {
                      "draggable-item": _vm.sections.length > 0,
                      "draggable-item-nosection": _vm.sections.length <= 0
                    }
                  },
                  [
                    _c("el-col", { attrs: { span: 2 } }, [
                      _c(
                        "span",
                        {
                          staticClass: "column-drag-handle",
                          staticStyle: { padding: "20px" }
                        },
                        [_vm._v("☰")]
                      )
                    ]),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "margin-top": "10px",
                          "margin-bottom": "10px"
                        },
                        attrs: { span: 2 }
                      },
                      [
                        _c("img", {
                          staticClass: "icon-room",
                          attrs: { src: "/assets/icons/" + item.icon + ".png" },
                          on: { error: _vm.onImageError }
                        })
                      ]
                    ),
                    _c("el-col", { attrs: { span: 10 } }, [
                      _vm._v("\n          " + _vm._s(item.name) + "\n        ")
                    ]),
                    _c("el-col", { attrs: { span: 7 } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.accessoryCount(item.id)) +
                          "\n        "
                      )
                    ]),
                    _c("el-col", { attrs: { span: 3 } }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.onClickEditRoom(item)
                            }
                          }
                        },
                        [_c("i", { staticClass: "far fa-edit" })]
                      ),
                      item.removable
                        ? _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.dublicateRoom(item)
                                }
                              }
                            },
                            [_c("i", { staticClass: "far fa-copy" })]
                          )
                        : _vm._e(),
                      item.removable
                        ? _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.removeRoom(item.id)
                                }
                              }
                            },
                            [_c("i", { staticClass: "far fa-trash-alt" })]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "AccessoryAdd",
                                params: { roomId: item.id }
                              })
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-plus" })]
                      )
                    ])
                  ],
                  1
                )
              ])
            }),
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("room.delete-section"),
            visible: _vm.deleteSectionPopupVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.deleteSectionPopupVisible = $event
            }
          }
        },
        [
          _c("el-alert", {
            attrs: {
              "show-icon": "",
              closable: false,
              title: _vm.$t("alert.message.confirm-delete"),
              type: "warning"
            }
          }),
          _vm.assignableSections.length > 0 &&
          _vm.getSectionRooms(_vm.deletedSectionId).length > 0
            ? _c(
                "el-form",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c("el-label", [
                    _vm._v(_vm._s(_vm.$t("room.section-assigned")))
                  ]),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        label: _vm.$t("room.section"),
                        "label-width": "100",
                        rules: { required: true }
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: _vm.$t("room.select-section") },
                          model: {
                            value: _vm.assignableSectionId,
                            callback: function($$v) {
                              _vm.assignableSectionId = $$v
                            },
                            expression: "assignableSectionId"
                          }
                        },
                        _vm._l(_vm.assignableSections, function(
                          section,
                          index
                        ) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: section.name, value: section.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deleteSectionPopupVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("room.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDeleteSection }
                },
                [_vm._v(_vm._s(_vm.$t("room.confirm")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("room.duplicate-room"),
            visible: _vm.dublicatePopupVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dublicatePopupVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "dublicateForm", attrs: { model: _vm.dublicatedRoom } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("room.name"),
                    "label-width": "100",
                    prop: "name",
                    rules: { required: true }
                  }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dublicatedRoom.name,
                      callback: function($$v) {
                        _vm.$set(_vm.dublicatedRoom, "name", $$v)
                      },
                      expression: "dublicatedRoom.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dublicatePopupVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("room.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDublicate }
                },
                [_vm._v(_vm._s(_vm.$t("room.confirm")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("room.delete-room"),
            visible: _vm.deletePopupVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.deletePopupVisible = $event
            },
            closed: function($event) {
              return _vm.$emit("cancelDeleteDialog")
            }
          }
        },
        [
          _c("el-alert", {
            attrs: {
              "show-icon": "",
              closable: false,
              title: _vm.$t("alert.message.confirm-delete"),
              type: "warning"
            }
          }),
          _c(
            "el-form",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { "label-position": "left" }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("room.delete-scenes"),
                    "label-width": "300px"
                  }
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.deleteScenes,
                      callback: function($$v) {
                        _vm.deleteScenes = $$v
                      },
                      expression: "deleteScenes"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("room.delete-accessories"),
                    "label-width": "300px"
                  }
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.deleteAccessories,
                      callback: function($$v) {
                        _vm.deleteAccessories = $$v
                      },
                      expression: "deleteAccessories"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deletePopupVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("room.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDelete }
                },
                [_vm._v(_vm._s(_vm.$t("room.confirm")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("NewRoomDialog", {
        ref: "roomDialog",
        attrs: {
          visible: _vm.newDialogVisible,
          room: _vm.activeRoom,
          "edit-mode": _vm.editMode
        },
        on: {
          saveRoom: _vm.saveRoom,
          cancelDialog: function($event) {
            _vm.newDialogVisible = false
          }
        }
      }),
      _c("section-popup", {
        ref: "sectionPopup",
        attrs: {
          visible: _vm.newSectionPopupVisible,
          section: _vm.activeSection,
          editMode: _vm.editModeSection
        },
        on: {
          saveSection: _vm.saveSection,
          cancelDialog: function($event) {
            _vm.newSectionPopupVisible = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }