<template>
  <div class="room">
    <el-row style="display: flex;">
      <el-col :span="20">
        <app-navigation :title="$t('room.rooms')"></app-navigation>
      </el-col>
      <el-col :span="4" class="btn-group">
        <el-button class="add-button" type="primary" @click="onClickAddRoom">{{$t('room.add-room')}}</el-button>
        <el-button type="plain" @click="onClickAddSection">{{$t('room.add-section')}}</el-button>
      </el-col>
    </el-row>


    <!-- <app-navigation
      :title="$t('room.rooms')"
      actionButtonIcon="fa fa-plus"
      :actionButtonDesc="$t('room.add-new-room')"
      @action="onClickAddRoom"
    ></app-navigation> -->



    <el-row
      v-if="sections.length > 0"
      style="margin-bottom: 10px; font-weight: bold"
      class="header-row"
    >
      <el-col :span="2">
        <span style="margin-left: 20px">#</span>
      </el-col>
      <el-col :span="19"> {{$t('room.section')}} </el-col>
      <el-col :span="3">
        {{ $t("widget.actions") }}
      </el-col>
    </el-row>

    <el-collapse v-if="sections.length > 0" v-model="activeNames">
      <Container
        v-if="true"
        @drop="onDropSection"
        drag-handle-selector=".column-drag-handle"
        v-loading="loading"
      >
        <Draggable v-for="section in sections" :key="section.id">
          <el-collapse-item :name="section.id" class="draggable-section">
            <template slot="title">
              <el-col :span="2">
                <span class="column-drag-handle" style="padding: 20px"
                  >&#x2630;</span
                >
              </el-col>
              <el-col :span="19"> {{ section.name }} </el-col>
              <el-col :span="3">
                <span @click="onClickEditSection(section)"
                  ><i class="far fa-edit"></i
                ></span>
                <span @click="removeSection(section.id)"
                  ><i class="far fa-trash-alt"></i
                ></span>
              </el-col>
            </template>

            <el-row class="room-title">
              <el-col :span="2">
                <span style="margin-left: 20px">#</span>
              </el-col>
              <el-col :span="2">
                {{ $t("room.icon") }}
              </el-col>
              <el-col :span="10">
                {{ $t("room.name") }}
              </el-col>
              <el-col :span="7">
                {{ $t("room.accessory-count") }}
              </el-col>
              <el-col :span="3">
                {{ $t("widget.actions") }}
              </el-col>
            </el-row>

            <Container
              v-if="true"
              @drop="onDrop(section, $event)"
              group-name="section"
              drag-handle-selector=".column-drag-handle"
              :get-child-payload="getChildPayload(section.id)"
              v-loading="loading"
            >
              <Draggable
                v-for="item in getSectionRooms(section.id)"
                :key="item.id"
              >
                <div class="draggable-item">
                  <el-col :span="2">
                    <span class="column-drag-handle" style="padding: 20px"
                      >&#x2630;</span
                    >
                  </el-col>
                  <el-col
                    :span="2"
                    style="
                      display: flex;
                      align-items: center;
                      margin-top: 10px;
                      margin-bottom: 10px;
                    "
                  >
                    <img
                      @error="onImageError"
                      :src="'/assets/icons/' + item.icon + '.png'"
                      class="icon-room"
                    />
                  </el-col>
                  <el-col :span="10">
                    {{ item.name }}
                  </el-col>
                  <el-col :span="7">
                    {{ accessoryCount(item.id) }}
                  </el-col>
                  <el-col :span="3">
                    <span @click="onClickEditRoom(item)"
                      ><i class="far fa-edit"></i
                    ></span>
                    <span v-if="item.removable" @click="dublicateRoom(item)"
                      ><i class="far fa-copy"></i
                    ></span>
                    <span v-if="item.removable" @click="removeRoom(item.id)"
                      ><i class="far fa-trash-alt"></i
                    ></span>
                    <span
                      @click="
                        $router.push({
                          name: 'AccessoryAdd',
                          params: { roomId: item.id },
                        })
                      "
                      ><i class="fas fa-plus"></i
                    ></span>
                  </el-col>
                </div>
              </Draggable>
            </Container>
          </el-collapse-item>
        </Draggable>
      </Container>
    </el-collapse>

    <el-row
      v-if="sections.length <= 0"
      :class="{
        'room-title': sections.length > 0,
        'room-title-nosection': sections.length <= 0,
      }"
    >
      <el-col :span="2">
        <span style="margin-left: 20px">#</span>
      </el-col>
      <el-col :span="2">
        {{ $t("room.icon") }}
      </el-col>
      <el-col :span="10">
        {{ $t("room.name") }}
      </el-col>
      <el-col :span="7">
        {{ $t("room.accessory-count") }}
      </el-col>
      <el-col :span="3">
        {{ $t("widget.actions") }}
      </el-col>
    </el-row>

    <Container
      v-if="sections.length <= 0"
      @drop="onDropRoom"
      drag-handle-selector=".column-drag-handle"
      v-loading="loading"
    >
      <Draggable v-for="item in items" :key="item.id">
        <div
          :class="{
            'draggable-item': sections.length > 0,
            'draggable-item-nosection': sections.length <= 0,
          }"
        >
          <el-col :span="2">
            <span class="column-drag-handle" style="padding: 20px"
              >&#x2630;</span
            >
          </el-col>
          <el-col
            :span="2"
            style="
              display: flex;
              align-items: center;
              margin-top: 10px;
              margin-bottom: 10px;
            "
          >
            <img
              @error="onImageError"
              :src="'/assets/icons/' + item.icon + '.png'"
              class="icon-room"
            />
          </el-col>
          <el-col :span="10">
            {{ item.name }}
          </el-col>
          <el-col :span="7">
            {{ accessoryCount(item.id) }}
          </el-col>
          <el-col :span="3">
            <span @click="onClickEditRoom(item)"
              ><i class="far fa-edit"></i
            ></span>
            <span v-if="item.removable" @click="dublicateRoom(item)"
              ><i class="far fa-copy"></i
            ></span>
            <span v-if="item.removable" @click="removeRoom(item.id)"
              ><i class="far fa-trash-alt"></i
            ></span>
            <span
              @click="
                $router.push({
                  name: 'AccessoryAdd',
                  params: { roomId: item.id },
                })
              "
              ><i class="fas fa-plus"></i
            ></span>
          </el-col>
        </div>
      </Draggable>
    </Container>

    <el-dialog
      :title="$t('room.delete-section')"
      :visible.sync="deleteSectionPopupVisible"
    >
    <el-alert
        show-icon
        :closable="false"
        :title="$t('alert.message.confirm-delete')"
        type="warning"
      >
      </el-alert>
      <el-form v-if="assignableSections.length > 0 && getSectionRooms(deletedSectionId).length > 0" style="margin-top: 20px">
        <el-label>{{$t('room.section-assigned')}}</el-label>
        <el-form-item
          style="margin-top: 10px"
          :label="$t('room.section')"
          label-width="100"
          :rules="{ required: true }"
        >
          <el-select v-model="assignableSectionId" :placeholder="$t('room.select-section')">
            <el-option v-for="(section, index) in assignableSections" :key="index" :label="section.name" :value="section.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="deleteSectionPopupVisible = false">{{
          $t("room.cancel")
        }}</el-button>
        <el-button type="primary" @click="confirmDeleteSection">{{
          $t("room.confirm")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('room.duplicate-room')"
      :visible.sync="dublicatePopupVisible"
    >
      <el-form ref="dublicateForm" :model="dublicatedRoom">
        <el-form-item
          :label="$t('room.name')"
          label-width="100"
          prop="name"
          :rules="{ required: true }"
        >
          <el-input v-model="dublicatedRoom.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dublicatePopupVisible = false">{{
          $t("room.cancel")
        }}</el-button>
        <el-button type="primary" @click="confirmDublicate">{{
          $t("room.confirm")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('room.delete-room')"
      :visible.sync="deletePopupVisible"
      @closed="$emit('cancelDeleteDialog')"
    >
      <el-alert
        show-icon
        :closable="false"
        :title="$t('alert.message.confirm-delete')"
        type="warning"
      >
      </el-alert>

      <el-form label-position="left" style="margin-top: 20px">
        <el-form-item :label="$t('room.delete-scenes')" label-width="300px">
          <el-switch v-model="deleteScenes"></el-switch>
        </el-form-item>

        <el-form-item
          :label="$t('room.delete-accessories')"
          label-width="300px"
        >
          <el-switch v-model="deleteAccessories"></el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="deletePopupVisible = false">{{
          $t("room.cancel")
        }}</el-button>
        <el-button type="primary" @click="confirmDelete">{{
          $t("room.confirm")
        }}</el-button>
      </span>
    </el-dialog>

    <NewRoomDialog
      ref="roomDialog"
      :visible="newDialogVisible"
      @saveRoom="saveRoom"
      @cancelDialog="newDialogVisible = false"
      :room="activeRoom"
      :edit-mode="editMode"
    ></NewRoomDialog>

    <section-popup
      ref="sectionPopup"
      :visible="newSectionPopupVisible"
      @saveSection="saveSection"
      @cancelDialog="newSectionPopupVisible = false"
      :section="activeSection"
      :editMode="editModeSection"
    >
    </section-popup>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import NewRoomDialog from "./NewRoomDialog";
import SectionPopup from "./SectionPopup";
import { Container, Draggable } from "vue-smooth-dnd";
import { categoryName, className } from "@/utils/accessory-utils";
import { DRIVER_TYPE_CORECENTER } from "@/utils/driver-utils";
import { initializeWebSocket } from "@/utils/socket-utils"

export default {
  name: "Rooms",
  components: {
    "app-navigation": Navigation,
    NewRoomDialog,
    Container,
    Draggable,
    SectionPopup,
  },

  data: function () {
    return {
      items: [],
      sections: [],
      loading: false,
      newDialogVisible: false,
      activeRoom: {},
      editMode: false,

      dublicatePopupVisible: false,
      dublicatedRoom: {},

      deletePopupVisible: false,
      deleteScenes: false,
      deleteAccessories: false,
      deletedRoomId: -1,

      newSectionPopupVisible: false,
      activeSection: {},
      editModeSection: false,

      deleteSectionPopupVisible: false,
      deletedSectionId: -1,
      assignableSectionId: -1,
    };
  },

  computed: {
    assignableSections(){
      return this.sections.filter(section => section.id !== this.deletedSectionId)
    },
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.getSections();
      }
    }
  },

  methods: {
    categoryName,
    className,

    getChildPayload(sectionId) {
      return (index) => {
        // const section = this.sections.find(
        //   (section) => section.id === sectionId
        // );
        // if (section.items && section.items.length > 0)
        //   return section.items[index];
        // else return {};

        const items = this.items.filter((item) => item.sectionId === sectionId);
        if (items && items.length > 0) return items[index];
        else return {};
      };
    },

    getSectionRooms(sectionId) {
      const items = this.items.filter((item) => item.sectionId === sectionId);

      if (items) return items.sort((a, b) => a.rank - b.rank);
      else return [];
    },

    onImageError: function (e) {
      e.target.src = "/assets/icons/ic_home1.png";
    },

    dublicateRoom(room) {
      this.dublicatePopupVisible = true;
      this.dublicatedRoom = { ...room };
    },

    confirmDublicate() {
      this.$refs.dublicateForm.validate((valid) => {
        if (valid) {
          this.dublicatePopupVisible = false;
          const roomId = this.dublicatedRoom.id;
          delete this.dublicatedRoom.id;

          this.$store
            .dispatch("addRoom", this.dublicatedRoom)
            .then((response) => {
              this.items.push({ ...response.data });

              const accessories = this.$store.getters.accessories
                .filter(
                  (accessory) =>
                    accessory.roomId == roomId &&
                    this.isRemovableAccessory(accessory.driverId)
                )
                .map((accessory) => {
                  return {
                    type: this.className(accessory.category),
                    properties: { ...accessory, roomId: response.data.id },
                  };
                });

              console.log("confirmDublicate", accessories);

              accessories.forEach((accessory) => {
                this.$store.dispatch("addAccessory", accessory);
              });

              this.$message({
                message: this.$t(response.message),
                type: "success",
              });
            })
            .catch((reason) => {
              this.$message({
                message: this.$t(reason),
                type: "error",
              });
            });
        }
      });
    },

    confirmDeleteSection(){
      this.deleteSectionPopupVisible = false;
      this.loading = true;

      this.$store
        .dispatch("removeSection", {
          sectionId: this.deletedSectionId,
          assignableSectionId: this.assignableSectionId
        })
        .then((response) => {
          this.sections = [...this.$store.getters.sections];
          this.deletedSectionId = -1;
          this.assignableSectionId = -1;

          this.getRooms();

          this.$message({
            message: this.$t(response.message),
            type: "success",
          });
        })
        .catch((reason) => {
          this.loading = false;
          this.deletedSectionId = -1;
          this.assignableSectionId = -1;

          this.$message({
            message: this.$t(reason),
            type: "error",
          });
        });
    },

    confirmDelete() {
      this.deletePopupVisible = false;

      this.loading = true;

      console.log(this.deletedRoomId,this.deleteScenes, this.deleteAccessories)

      this.$store
      this.$store
        .dispatch("removeRoom", {
          roomId: this.deletedRoomId,
          deleteScenes: this.deleteScenes,
          deleteAccessories: this.deleteAccessories,
        })
        .then((response) => {
          this.items = [...this.$store.getters.rooms];
          this.deletedRoomId = -1;

          this.loading = false;

          this.$message({
            message: this.$t(response.message),
            type: "success",
          });
        })
        .catch((reason) => {
          this.loading = false;
          this.deletedRoomId = -1;
          console.log(reason)
          this.$message({
            message: this.$t(reason),
            type: "error",
          });
        });
    },

    isRemovableAccessory(driverId) {
      return (
        this.$store.getters.drivers.find((driver) => driver.id == driverId)
          .driverType != DRIVER_TYPE_CORECENTER
      );
    },

    accessoryCount: function (roomId) {
      return this.$store.getters.accessories.filter(
        (accessory) => accessory.roomId == roomId
      ).length;
    },

    saveSection(section, editMode) {
      this.newSectionPopupVisible = false;

      const newSection = { ...section };

      if (!editMode) {
        const maxRank = this.sections.reduce(
          (max, item) => (item.rank > max ? item.rank : max),
          this.sections.length > 0 ? this.sections[0].rank : -1
        );
        newSection.rank = maxRank + 1;
      }

      console.log("newSection", newSection);

      this.$store
        .dispatch(editMode ? "editSection" : "addSection", newSection)
        .then((response) => {
          const newItem = response.data;

          if (!editMode) {
            this.sections.push(newItem);
          } else {
            this.sections = this.sections.map((item) => {
              if (item.id == newItem.id) {
                return newItem;
              } else {
                return item;
              }
            });
          }

          this.getRooms();

          this.$message({
            message: this.$t(response.message),
            type: "success",
          });
        })
        .catch((reason) => {
          this.$message({
            message: this.$t(reason),
            type: "error",
          });
        });
    },

    saveRoom(room, editMode) {
      this.newDialogVisible = false;
      console.log("old", room);

      const newRoom = { ...room, removable: true };

      if (!editMode) {
        const maxRank = this.items.reduce(
          (max, item) => (item.rank > max ? item.rank : max),
          this.items[0].rank
        );
        newRoom.rank = maxRank + 1;
      }

      console.log("new", newRoom);

      this.$store
        .dispatch(editMode ? "editRoom" : "addRoom", newRoom)
        .then((response) => {
          const newItem = response.data;

          if (!editMode) {
            this.items.push(newItem);
          } else {
            this.items = this.items.map((item) => {
              if (item.id == newItem.id) {
                return newItem;
              } else {
                return item;
              }
            });
          }

          this.$message({
            message: this.$t(response.message),
            type: "success",
          });
        })
        .catch((reason) => {
          this.$message({
            message: this.$t(reason),
            type: "error",
          });
        });
    },

    onClickEditRoom(room) {
      this.activeRoom = room;
      this.editMode = true;
      this.newDialogVisible = true;
    },

    onClickEditSection(section){
        this.activeSection = section;
      this.editModeSection = true;
      this.newSectionPopupVisible = true;
    },

    onClickAddRoom() {
      this.activeRoom = {
        icon: "ic_home1",
        sectionId: this.sections.length > 0 ? this.sections[0].id : -1
      };
      this.editMode = false;
      this.newDialogVisible = true;
    },

    onClickAddSection() {
      this.activeSection = {};
      this.editModeSection = false;
      this.newSectionPopupVisible = true;
    },

    removeSection(sectionId){
      this.deletedSectionId = sectionId;
      const assignables = this.assignableSections
      if (assignables.length > 0)
        this.assignableSectionId = assignables[0].id
        
      console.log("removeSection", sectionId, assignables)
      
      this.deleteSectionPopupVisible = true;
    },

    removeRoom: function (roomId) {
      this.deleteScenes = false;
      this.deleteAccessories = false;
      this.deletePopupVisible = true;
      this.deletedRoomId = roomId;

      /*this.$confirm(this.$t('alert.message.confirm-delete'), this.$t('alert.title.warning'), {
          confirmButtonText: this.$t('alert.button.ok'),
          cancelButtonText: this.$t('alert.button.cancel'),
          type: 'warning'
        }).then(() => {
          this.loading = true

          this.$store.dispatch('removeRoom', roomId).then(response => {
            this.items = [...this.$store.getters.rooms]

            this.loading = false

            this.$message({
              message: response.message,
              type: 'success'
            })
          }).catch(reason => {
            this.loading = false
            this.$message({
              message: reason,
              type: 'error'
            })
          })
        })*/
    },

    onDropSection(dropResult) {
      console.log("dropResultSection", dropResult);
      this.sections = this.applyDrag(this.sections, dropResult);
      console.log("rooms", this.sections);

      this.$store
        .dispatch("syncSections", [...this.sections])
        .then((response) => {
          this.$message({
            message: this.$t(response.message),
            type: "success",
          });
        })
        .catch((reason) => {
          this.$message({
            message: this.$t(reason),
            type: "error",
          });
        });
    },

    onDrop(section, dropResult) {
      console.log("dropResult", section, dropResult);
      //this.items = this.applyDrag(this.$store.getters.rooms, dropResult);

      //const section = this.sections.find((section) => section.id === sectionId);

      //section.items = this.applyDrag(section.items, dropResult);

      const items = this.applyDrag(
        this.getSectionRooms(section.id),
        dropResult
      );

      this.items = this.items.map((item) => {
        const newItem = items.find((i) => i.id === item.id);
        if (newItem) return { ...newItem, sectionId: section.id };
        else return item;
      });

      console.log("rooms", this.items, items, this.getSectionRooms(section.id));

      this.$store
        .dispatch("syncRooms", [...this.items])
        .then((response) => {
          this.$message({
            message: this.$t(response.message),
            type: "success",
          });
        })
        .catch((reason) => {
          this.$message({
            message: this.$t(reason),
            type: "error",
          });
        });
    },

    onDropRoom(dropResult) {
      console.log("dropRoomResult", dropResult);
      this.items = this.applyDrag(this.$store.getters.rooms, dropResult);

      console.log("rooms", this.items);

      this.$store
        .dispatch("syncRooms", [...this.items])
        .then((response) => {
          this.$message({
            message: this.$t(response.message),
            type: "success",
          });
        })
        .catch((reason) => {
          this.$message({
            message: this.$t(reason),
            type: "error",
          });
        });
    },

    applyDrag(arr, dragResult) {
      console.log("applyDrag", arr, dragResult);
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      let result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result.map((item, index) => {
        //const section = this.sections.find(section => section.id === item.sectionId)
        return { ...item, rank: index };
      });
    },

    getSections() {
      this.$store
        .dispatch("getSections")
        .then((message) => {
          this.sections = [...this.$store.getters.sections];

          console.log("sections", this.sections);

          this.getRooms();
        })
        .catch((reason) => {
          this.getRooms();
        });
    },

    getRooms() {
      this.$store
        .dispatch("getRooms")
        .then((message) => {
          this.loading = false;
          this.items = [...this.$store.getters.rooms];

          console.log("rooms", this.items);
        })
        .catch((reason) => {
          this.loading = false;
        });
    },
  },

  created() {
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.getSections();
  },
};
</script>

<style scoped>
  @import "../../style/room.css";
.room-title-nosection {
  margin-bottom: 10px;
  color: #00BFD7;
  font-size: 14px;

}

.room-title {
  margin-bottom: 10px;
  margin-left: 40px;
  margin-right: 10px;
  font-weight: bold;
}

.draggable-section {
  text-align: left;
  width: 100%;
  display: block;
  background-color: #fff;
  outline: 0;
  border-radius: 10px;
  box-shadow: 4px 4px 10px rgba(184,184,184, 0.5);
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: default;
  user-select: none;
}

.draggable-item-nosection {
  height: 50px;
  line-height: 50px;
  text-align: left;
  width: 100%;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: default;
  user-select: none;
}

.draggable-item {
  height: 50px;
  line-height: 50px;
  text-align: left;
  width: calc(100% - 50px);
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2px;
  margin-top: 2px;
  margin-left: 40px;
  margin-right: 10px;
  cursor: default;
  user-select: none;
  border-radius: 10px;
}
.el-collapse-item__header{
  font-size: 14px;
}

.column-drag-handle {
  cursor: move;
  padding: 5px;
}

span i {
  padding: 5px;
  cursor: pointer;
}

span i:hover {
  background-color: #00BFD7;
  border-radius: 5px;
  color: white;
}

span i:active {
  box-shadow: 0px 0px 10px #00BFD7;
  border-radius: 5px;
}

.icon-contact {
  filter: brightness(0.45);
  width: 40px;
  height: 40px;
}

.icon-room {
  filter: brightness(0.45);
  width: 30px;
  height: 30px;
}

.add-button {
  background-color: #00BFD7;
  color: white;
}
.btn-group {
  display: flex;
    height: 40px;
    top: 8%;
    right: 1%;
    align-self: center;
    justify-self: end;
    min-width: 250px;
}
</style>