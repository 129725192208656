var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.editMode
          ? _vm.$t("room.update-room")
          : _vm.$t("room.add-room"),
        visible: _vm.visible
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        closed: function($event) {
          return _vm.$emit("cancelDialog")
        }
      }
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.room } },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("room.icon"), "label-width": "120px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("room.select-icon")
                      },
                      model: {
                        value: _vm.room.icon,
                        callback: function($$v) {
                          _vm.$set(_vm.room, "icon", $$v)
                        },
                        expression: "room.icon"
                      }
                    },
                    _vm._l(_vm.icons, function(icon, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: icon.title, value: icon.value }
                        },
                        [
                          _c("span", { staticClass: "icon-select-layout" }, [
                            _c("img", {
                              staticClass: "icon-select",
                              attrs: {
                                src: "/assets/icons/" + icon.value + ".png"
                              },
                              on: { error: _vm.onImageError }
                            }),
                            _vm._v(_vm._s(icon.title))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", [
                  _c("img", {
                    staticClass: "icon-contact",
                    attrs: { src: "/assets/icons/" + _vm.room.icon + ".png" },
                    on: { error: _vm.onImageError }
                  })
                ])
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("room.room-name"),
                "label-width": "120px",
                prop: "name",
                rules: { required: true }
              }
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.saveRoom($event)
                  }
                },
                model: {
                  value: _vm.room.name,
                  callback: function($$v) {
                    _vm.$set(_vm.room, "name", $$v)
                  },
                  expression: "room.name"
                }
              })
            ],
            1
          ),
          _vm.$store.getters.sections.length > 0
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("room.section"),
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("room.select-section") },
                      model: {
                        value: _vm.room.sectionId,
                        callback: function($$v) {
                          _vm.$set(_vm.room, "sectionId", $$v)
                        },
                        expression: "room.sectionId"
                      }
                    },
                    _vm._l(_vm.$store.getters.sections, function(
                      section,
                      index
                    ) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: section.name, value: section.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("cancelDialog")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("room.cancel")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveRoom } },
            [_vm._v(_vm._s(_vm.$t("room.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }