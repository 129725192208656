<template>
  <el-dialog :title="editMode ? $t('room.update-section') : $t('room.add-section')" :visible.sync="visible" @closed="$emit('cancelDialog')">

    <el-form ref="form" :model="section">
      <el-form-item :label="$t('room.section-name')" label-width="120px" prop="name" :rules="{required: true}">
        <el-input v-model="section.name" autocomplete="off" @keydown.enter.native="saveSection"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
    <el-button @click="$emit('cancelDialog')">{{ $t('room.cancel') }}</el-button>
    <el-button type="primary" @click="saveSection">{{ $t('room.confirm') }}</el-button>
  </span>
  </el-dialog>
</template>

<script>
  export default {
    name: "SectionPopup",

    props: {
      visible: {
        type: Boolean,
        required: true,
        default: false
      },

      editMode: {
        type: Boolean,
        default: false
      },

      section: {}
    },

    methods: {
      saveSection(){
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$emit('saveSection', this.section, this.editMode)
          }
        })
      }
    },
  }
</script>

<style scoped>
  .icon-select-layout{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon-select {
    filter: brightness(0.45);
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .icon-contact {
    filter: brightness(0.45);
    width: 40px;
    height: 40px;
  }
</style>